import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/welcomeView.vue')
  },
  {
    path: '/dd1',
    name: 'dd1',
    component: () => import('../views/dd1View.vue')
  },
  {
    path: '/dd2',
    name: 'dd2',
    component: () => import('../views/dd2View.vue')
  },
  {
    path: '/refuse',
    name: 'refuse',
    component: () => import('../views/refuseView.vue')
  },
  {
    path: '/accept',
    name: 'accept',
    component: () => import('../views/acceptView.vue')
  },
  {
    path: '/dd2',
    name: 'dd2',
    component: () => import('../views/dd2View.vue')
  },
  {
    path: '/adminwelcome',
    name: 'adminwelcome',
    component: () => import('../views/adminWelcomeView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/adminView.vue')
  },
  {
    path: '/null',
    name: 'null',
    component: () => import('../views/nullView.vue')
  },
  {
    path: '*',
    redirect: '/welcome'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
